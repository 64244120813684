import { useEffect } from 'react';
import { Flex, Loader, Tabs } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Authenticated, Typography } from '@/common/components/index.ts';
import {
  useApiProvider,
  useAuthProvider,
  useHeaderProvider
} from '@/common/providers/index.ts';
import { useQueriesData } from '@/common/hooks/index.ts';
import { getPets } from '@/common/queries/pets.ts';
import { getIndividuals } from '@/common/queries/individuals.ts';
import { getVehicles } from '@/common/queries/vehicles.ts';
import { getFinancialRelations } from '@/common/queries/finacial_relations.ts';
import { getBanks } from '@/common/queries/banks.ts';
import { getUser } from '@/common/queries/users.ts';
import {
  IAPIIndividual,
  IAPIIndividualBankAccount,
  IAPIIndividualPet,
  IAPIIndividualVehicle,
  IAPIUserI13lFinancialRelation
} from '@zudi/types';

import {
  AssociateForm,
  BeneficiaryForm,
  FinancialRelationForm,
  PetForm
} from './components/index.tsx';
import styles from './view.module.scss';
import { VehicleForm } from './components/VehicleForms/component.tsx';
import { BankAccountForm } from './components/BankAccountForm/component.tsx';

const InformationUpdateView = () => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const authProvider = useAuthProvider();
  const { setTitle } = useHeaderProvider();

  const user = useQuery({
    queryKey: ['users', { id: authProvider.cognitoUser?.claims.sub }],
    queryFn: async (qfnContext) => {
      if (!authProvider.cognitoUser) {
        throw new Error(
          'Authenticated user is required for the fetch operation'
        );
      }
      return getUser(authProvider.cognitoUser.claims.sub, {
        qfnContext,
        apiProvider
      });
    },
    enabled: !!authProvider.cognitoUser
  });

  const individuals = useQueriesData<IAPIIndividual>(
    {
      queryKey: ['individuals']
    },
    queryClient
  );

  const pets = useQueriesData<IAPIIndividualPet>(
    {
      queryKey: ['pets']
    },
    queryClient
  );

  const vehicles = useQueriesData<IAPIIndividualVehicle>(
    {
      queryKey: ['vehicles']
    },
    queryClient
  );

  const financialRelations = useQueriesData<IAPIUserI13lFinancialRelation>(
    {
      queryKey: ['financial_relations']
    },
    queryClient
  );

  const bankAccounts = useQueriesData<IAPIIndividualBankAccount>(
    {
      queryKey: ['bank_accounts']
    },
    queryClient
  );

  useEffect(() => {
    setTitle('Actualización Información Asociado');

    getIndividuals({ apiProvider }).then((results) =>
      results.forEach((entity) =>
        queryClient.setQueryData<typeof entity>(
          ['individuals', { id: entity.id }],
          entity
        )
      )
    );

    getPets({ apiProvider }).then((results) =>
      results.forEach((entity) =>
        queryClient.setQueryData<typeof entity>(
          ['pets', { id: entity.id }],
          entity
        )
      )
    );

    getVehicles({ apiProvider }).then((results) =>
      results.forEach((entity) =>
        queryClient.setQueryData<typeof entity>(
          ['vehicles', { id: entity.id }],
          entity
        )
      )
    );

    getFinancialRelations({ apiProvider }).then((results) =>
      results.forEach((entity) =>
        queryClient.setQueryData<typeof entity>(
          ['financial_relations', { id: entity.id }],
          entity
        )
      )
    );

    getBanks({ apiProvider }).then((results) =>
      results.forEach((entity) =>
        queryClient.setQueryData<typeof entity>(
          ['bank_accounts', { id: entity.id }],
          entity
        )
      )
    );
  }, []);

  return (
    <Authenticated>
      <div className={styles.main}>
        {user.isLoading && <Loader size="lg" variant="bars" />}
        {user.data !== undefined && (
          <Tabs variant="pills" orientation="vertical" defaultValue="associate">
            <Tabs.List pr="sm">
              <Tabs.Tab value="associate">Asociado</Tabs.Tab>
              <Tabs.Tab value="beneficiaries">Beneficiarios</Tabs.Tab>

              <Tabs.Tab value="bankAccounts">Cuentas Bancarias</Tabs.Tab>
              <Tabs.Tab value="registeredVehicles">Vehículos</Tabs.Tab>
              <Tabs.Tab value="registeredPets">Mascotas</Tabs.Tab>
              <Tabs.Tab value="internationalTransfers">
                Transferencias Intl.
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="associate">
              {user.data.cognitoSubId && (
                <AssociateForm
                  id={user.data.cognitoSubId}
                  capabilities={['save']}
                />
              )}
            </Tabs.Panel>

            <Tabs.Panel value="beneficiaries">
              <Flex direction="column" gap="10px">
                {individuals.map(
                  ([_, individual]) =>
                    individual && (
                      <BeneficiaryForm
                        key={individual.id}
                        id={individual.id}
                        capabilities={['delete', 'save']}
                      />
                    )
                )}
                <BeneficiaryForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="internationalTransfers">
              <Flex direction="column" gap="10px">
                <Typography.Title variants={['large']}>
                  Información Financiera Internacional
                </Typography.Title>
                {financialRelations.map(
                  ([_, financialRelation]) =>
                    financialRelation && (
                      <FinancialRelationForm
                        key={financialRelation.id}
                        id={financialRelation.id}
                        capabilities={['delete', 'save']}
                      />
                    )
                )}
                <FinancialRelationForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="bankAccounts">
              <Flex direction="column" gap="10px">
                <Typography.Title variants={['large']}>
                  Cuentas Bancarias
                </Typography.Title>
                {bankAccounts.map(
                  ([_, bankAccount]) =>
                    bankAccount && (
                      <BankAccountForm
                        key={bankAccount.id}
                        id={bankAccount.id}
                        capabilities={['delete', 'save']}
                      />
                    )
                )}
                <BankAccountForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="registeredPets">
              <Flex direction="column" gap="10px">
                <Typography.Title variants={['large']}>
                  Mascotas Registradas
                </Typography.Title>
                {pets.map(
                  ([_, pet]) =>
                    pet && (
                      <PetForm
                        key={pet.id}
                        id={pet.id}
                        capabilities={['delete', 'save']}
                      />
                    )
                )}
                <PetForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="registeredVehicles">
              <Flex direction="column" gap="10px">
                <Typography.Title variants={['large']}>
                  Vehículos Registrados
                </Typography.Title>
                {vehicles.map(
                  ([_, vehicle]) =>
                    vehicle && (
                      <VehicleForm
                        key={vehicle.id}
                        id={vehicle.id}
                        capabilities={['delete', 'save']}
                      />
                    )
                )}
                <VehicleForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>
          </Tabs>
        )}
      </div>
    </Authenticated>
  );
};

export { InformationUpdateView };
