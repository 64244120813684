import React, { ComponentProps } from 'react';
import { NumberInput as MantineNumberInput } from '@mantine/core';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';
import lodash from 'lodash';

const NumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    ComponentProps<typeof MantineNumberInput>
) => {
  const { field, formState } = useController(props);

  const error = lodash.get(formState.errors, field.name)?.message as
    | string
    | undefined;

  return (
    <MantineNumberInput
      {...props}
      {...field}
      error={error}
      value={field.value ? parseFloat(String(field.value)) : ''}
      onChange={(value) => field.onChange(value as any)}
    />
  );
};

export { NumberInput };
