import { clsx } from 'clsx';

import styles from './component.module.scss';

const Title = ({
  variants,
  className,
  children
}: {
  variants?: ('medium' | 'large')[];
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <span
      className={clsx(
        styles.main,
        variants?.includes('medium') && styles.sizeMedium,
        variants?.includes('large') && styles.sizeLarge,
        className
      )}
    >
      {children}
    </span>
  );
};

export { Title };
