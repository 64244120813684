enum GenderType {
  MASCULINO = 'MASCULINO',
  FEMENINO = 'FEMENINO',
  OTHER = 'OTHER'
}

enum DocumentType {
  CEDULA_CIUDADANIA = 'CC',
  CEDULA_EXTRANJERIA = 'CE',
  TARJETA_IDENTIDAD = 'TI',
  REGISTRO_CIVIL = 'RC'
}

enum MaritalStatusType {
  SOLTERO = 'SOLTERO',
  UNION_LIBRE = 'UNION_LIBRE',
  CASADO = 'CASADO'
}

enum EducationLevelType {
  PRIMARIA = 'PRIMARIA',
  SECUNDARIA = 'SECUNDARIA',
  TECNICO = 'TECNICO',
  UNIVERSITARIA = 'UNIVERSITARIA',
  POSTGRADO = 'POSTGRADO',
  MASTER = 'MASTER',
  DOCTORADO = 'DOCTORADO'
}

enum ResidenceType {
  CASA = 'CASA',
  APARTAMENTO = 'APARTAMENTO',
  LOTE = 'LOTE'
}

enum ResidenceOwnershipType {
  PROPIA_HIPOTECA = 'PROPIA_HIPOTECA',
  PROPIA_NO_HIPOTECA = 'PROPIA_NO_HIPOTECA',
  FAMILIAR = 'FAMILIAR',
  ARRIENDO = 'ARRIENDO'
}

enum ResidencePermanencyType {
  ONE_YEAR = 'ONE_YEAR',
  ONE_TO_FIVE_YEARS = 'ONE_TO_FIVE_YEARS',
  FIVE_YEARS_PLUS = 'FIVE_YEARS_PLUS'
}

enum VehicleType {
  MOTOCICLETA = 'MOTOCICLETA',
  AUTOMOVIL = 'AUTOMOVIL',
  CAMPERO = 'CAMPERO',
  CAMIONETA = 'CAMIONETA',
  OTRO = 'OTRO'
}

enum BankAccountType {
  AHORROS = 'AHORROS',
  CORRIENTE = 'CORRIENTE'
}

enum AuthorizationType {
  TRATAMIENTO_DATOS = 'TRATAMIENTO_DATOS',
  CONSULTA_REPORTE_CENTRALES = 'CONSULTA_REPORTE_CENTRALES',
  DESCUENTO_LIBRANZA = 'DESCUENTO_LIBRANZA',
  CONSULTA_STATUS_POS = 'CONSULTA_STATUS_POS'
}

export {
  GenderType,
  DocumentType,
  MaritalStatusType,
  EducationLevelType,
  ResidenceType,
  ResidenceOwnershipType,
  ResidencePermanencyType,
  VehicleType,
  BankAccountType,
  AuthorizationType
};
