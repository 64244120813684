import { Navigate, RouteObject } from 'react-router-dom';

import { SignInView } from '@/features/auth/views/index.ts';
import { InformationUpdateView } from '@/features/associate/views/index.ts';
import { DashboardView } from '@/features/dashboard/views/index.ts';
import { LegalDocumentsView } from '@/features/legal/views/index.ts';

const routes: RouteObject[] = [
  { element: <Navigate to="/dashboard" />, index: true },
  {
    path: '/dashboard',
    element: <DashboardView />
  },
  {
    path: '/legal/docs',
    element: <LegalDocumentsView />
  },
  {
    path: '/associate/information/update',
    element: <InformationUpdateView />
  },
  {
    path: '/auth/signin',
    element: <SignInView />
  }
];

export { routes };
