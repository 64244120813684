import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Anchor, Button, Flex, Modal, Text } from '@mantine/core';
import { Awaitable } from '@juandavidkincaid/utils';

import { IDataAuthorizationProviderContext } from './types.ts';

const DataAuthorizationProviderContext =
  React.createContext<IDataAuthorizationProviderContext | null>(null);

const DataAuthorizationProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const MinTime = dayjs.duration(5, 'minutes');

  const [lastAuthorizationDate, setLastAuthorizationDate] =
    useState<Dayjs | null>(null);

  const [pendingAuthorizationPromise, setPendingAuthorizationPromise] =
    useState<Awaitable | null>(null);

  const onCloseModal = () => {
    if (!pendingAuthorizationPromise) {
      return;
    }

    pendingAuthorizationPromise.reject('User data authorization rejected');

    setPendingAuthorizationPromise(null);
  };

  const onAuthorizationButtonClick = () => {
    if (!pendingAuthorizationPromise) {
      return;
    }

    const authorizedAt = dayjs();

    pendingAuthorizationPromise.resolve(authorizedAt.toISOString());

    setPendingAuthorizationPromise(null);

    setLastAuthorizationDate(authorizedAt);
  };

  const providerContext: IDataAuthorizationProviderContext = {
    requestUserDataAuthorization: async () => {
      if (
        lastAuthorizationDate &&
        dayjs().diff(lastAuthorizationDate) < MinTime.asMilliseconds()
      ) {
        return lastAuthorizationDate.toISOString();
      }

      const newPendingAuthorizationPromise = new Awaitable<string>();

      setPendingAuthorizationPromise(newPendingAuthorizationPromise);

      return newPendingAuthorizationPromise;
    }
  };

  return (
    <DataAuthorizationProviderContext.Provider value={providerContext}>
      {children}
      <Modal
        size={700}
        opened={pendingAuthorizationPromise !== null}
        onClose={onCloseModal}
        title="Autorización de datos"
      >
        <Flex direction={'column'} rowGap={20}>
          <Text sx={{ textAlign: 'justify' }}>
            En cumplimiento de la Ley 1581 de 2012 y el Decreto 1377 de 2013,
            sobre la protección de Datos Personales, en calidad de Asociado
            autorizo expresamente al FONDO DE EMPLEADOS DEL COLEGIO NUEVA
            GRANADA - femCNG, para almacenar, consultar, procesar, y en general
            dar tratamiento a la información personal y sensible que haya
            suministrado, o suministre en el futuro, tal como se tiene
            establecido en la{' '}
            <Anchor
              href="https://docs.google.com/document/d/e/2PACX-1vQZssZbLElysxUP1kqfF0j6LVrdRMZyuXu_zortgrxtvD6zngOH8XJcBYs/pub"
              target="_blank"
            >
              política de tratamiento de la información del fondo
            </Anchor>
            ; la cual es indispensable para desarrollar su objeto social y dar
            cumplimiento con las obligaciones que se derivan con ocasión de la
            vinculación que tengo con femCNG.
          </Text>
          <Button fullWidth onClick={onAuthorizationButtonClick}>
            Si, autorizo
          </Button>
        </Flex>
      </Modal>
    </DataAuthorizationProviderContext.Provider>
  );
};

export { DataAuthorizationProvider, DataAuthorizationProviderContext };
