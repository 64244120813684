import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from './client.ts';

const TanstackQueryClientProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export { TanstackQueryClientProvider };
