import { IAPIIndividualVehicle } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getVehicles = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualVehicle[];
  }>(`/vehicles`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const getVehicle = async (id: string, requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualVehicle;
  }>(`/vehicles/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const postVehicle = async (
  data: IAPIIndividualVehicle,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.post<{
    data: IAPIIndividualVehicle;
  }>(`/vehicles`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchVehicle = async (
  id: string,
  data: IAPIIndividualVehicle,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIIndividualVehicle;
  }>(`/vehicles/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const deleteVehicle = async (id: string, requestContext: IRequestContext) => {
  await requestContext.apiProvider.apiAuthenticated.delete<never>(
    `/vehicles/${id}`,
    {
      signal: requestContext.qfnContext?.signal
    }
  );
};

export { getVehicles, getVehicle, postVehicle, patchVehicle, deleteVehicle };
