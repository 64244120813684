import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { CognitoPool, CognitoPoolUser } from '@juandavidkincaid/cognito';

import { DeploymentRegions } from '@zudi/infra';
import { EnvironmentContext } from '@zudi/lib';

import { IAuthProviderContext } from './types.ts';

const AuthProviderContext = React.createContext<IAuthProviderContext | null>(
  null
);

const AuthProvider = ({
  children,
  userPoolId,
  userPoolClientId
}: {
  userPoolId: string;
  userPoolClientId: string;
  children: React.ReactNode;
}) => {
  const cognitoPool = useMemo(
    () =>
      new CognitoPool(
        'zudi-auth',
        DeploymentRegions[EnvironmentContext.stage],
        userPoolId,
        userPoolClientId
      ),
    []
  );
  const [cognitoUser, setCognitoUser] = useState<CognitoPoolUser | null>(
    cognitoPool.getStoreUser()
  );

  const refreshAuthTokens = async (cognitoUser: CognitoPoolUser) => {
    if (!cognitoUser.isRefreshable) {
      return;
    }

    await cognitoUser.getRefreshedAccessToken();
    cognitoUser.cognitoPool.setStoreUser(cognitoUser);

    setTimeout(
      () => refreshAuthTokens(cognitoUser),
      cognitoUser.authFlow.accessTokenExp.diff(dayjs())
    );
  };

  useEffect(() => {
    if (cognitoUser) {
      const logout = () => {
        cognitoPool.deleteStoreUser();
        setCognitoUser(null);
      };

      cognitoUser.on('refreshFlowFailure', logout);

      return () => {
        cognitoUser.off('refreshFlowFailure', logout);
      };
    }
  }, [cognitoUser]);

  useEffect(() => {
    if (cognitoUser && cognitoUser.isAuthenticated) {
      refreshAuthTokens(cognitoUser);
    }
  }, [cognitoUser]);

  const providerContext: IAuthProviderContext = {
    cognitoPool,
    cognitoUser,
    getUser: (username) => {
      const user = cognitoPool.getUser(username);
      user.on('authenticated', () => {
        setCognitoUser(user);
      });
      return user;
    },
    logoutUser: () => {
      cognitoPool.deleteStoreUser();
      setCognitoUser(null);
    }
  };

  return (
    <AuthProviderContext.Provider value={providerContext}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export { AuthProvider, AuthProviderContext };
