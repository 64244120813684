import { useEffect, useState } from 'react';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import {
  ChallengeNameType,
  CognitoPoolUser,
  ICognitoAuthChallenge
} from '@juandavidkincaid/cognito';

import {
  useAuthProvider,
  useHeaderProvider
} from '@/common/providers/index.ts';
import { useForm, useSignInRedirect } from '@/common/hooks/index.ts';
import { Card, Typography } from '@/common/components/index.ts';
import FemCNGLogoSvg from '@/assets/logos/femcng-long.svg';

import styles from './view.module.scss';

const SignInView = () => {
  const auth = useAuthProvider();
  const { setTitle } = useHeaderProvider();
  const consumeSignInRedirect = useSignInRedirect.useConsumeRedirect();

  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState<CognitoPoolUser | null>(null);
  const [currentChallenge, setCurrentChallenge] =
    useState<ICognitoAuthChallenge<any> | null>(null);

  const signInForm = useForm<{ username: string; password: string }>({});
  const signInFormSubmit = signInForm.onSubmit(async (data) => {
    const user = auth.getUser(data.username);

    try {
      const authenticated = await user
        .initiateAuthFlow()
        .userPasswordAuth(data.password);

      if (authenticated) {
        consumeSignInRedirect('/');
      } else {
        setCurrentChallenge(user.authFlow.lastChallenge);
      }
      setCurrentUser(user);
    } catch (e) {
      console.dir(e);
      if (
        String(e).match(
          /NotAuthorizedException: Incorrect username or password/i
        )
      ) {
        setError('Usuario o contraseña incorrectos');
      } else {
        setError(String(e));
      }
    }
  });

  const newPasswordForm = useForm<{ passwordA: string; passwordB: string }>({});
  const newPasswordFormSubmit = newPasswordForm.onSubmit(async (data) => {
    if (!currentUser) {
      throw new Error('Inconsistency Error => No current user for flow');
    }

    const user = currentUser;

    try {
      const authenticated = await user
        .respondAuthFlow()
        .newPasswordRequired(data.passwordA);

      if (authenticated) {
        consumeSignInRedirect('/');
      } else {
        setCurrentChallenge(user.authFlow.lastChallenge);
      }
    } catch (e) {
      console.dir(e);
      if (
        String(e).match(
          /NotAuthorizedException: Incorrect username or password/i
        )
      ) {
        setError('Usuario o contraseña incorrectos');
      } else {
        setError(String(e));
      }
    }
  });

  useEffect(() => {
    if (auth.cognitoUser) {
      consumeSignInRedirect('/');
    }
  }, [auth.cognitoUser]);

  useEffect(() => {
    setTitle('Iniciar Sesión');
  }, []);

  return (
    <main className={styles.main}>
      <Card className={styles.signInCard}>
        {currentUser === null && (
          <form onSubmit={signInFormSubmit} className={styles.signInForm}>
            <div className={styles.title}>
              <img
                src={FemCNGLogoSvg}
                alt="FemCNG Logo"
                width={100}
                height={50}
              />
              <Typography.Title>Portal Transaccional FemCNG</Typography.Title>
            </div>
            <TextInput
              label="Usuario"
              w="100%"
              {...signInForm.getInputProps('username', {
                required: 'El campo es requerido'
              })}
            />
            <PasswordInput
              label="Contraseña"
              w="100%"
              {...signInForm.getInputProps('password', {
                required: 'El campo es requerido'
              })}
            />
            {error && <span className={styles.error}>{error}</span>}
            <Button
              w="100%"
              type="submit"
              loading={signInForm.formState.isSubmitting}
            >
              Ingresar
            </Button>
          </form>
        )}
        {currentUser !== null &&
          currentChallenge !== null &&
          currentChallenge.name === ChallengeNameType.NEW_PASSWORD_REQUIRED && (
            <form
              onSubmit={newPasswordFormSubmit}
              className={styles.signInForm}
            >
              <div className={styles.title}>
                <img
                  src={FemCNGLogoSvg}
                  alt="FemCNG Logo"
                  width={100}
                  height={50}
                />
                <Typography.Title>Portal Transaccional FemCNG</Typography.Title>
              </div>
              <PasswordInput
                label="Nueva Contraseña"
                w="100%"
                {...newPasswordForm.getInputProps('passwordA', {
                  required: 'El campo es requerido'
                })}
              />
              <PasswordInput
                label="Repita Contraseña"
                w="100%"
                {...newPasswordForm.getInputProps('passwordB', {
                  required: 'El campo es requerido',
                  validate: {
                    passwordMatch: (value) =>
                      newPasswordForm.getValues().passwordA === value ||
                      'Las contraseñas deben ser similares'
                  }
                })}
              />
              {error && <span className={styles.error}>{error}</span>}
              <Button
                w="100%"
                type="submit"
                loading={newPasswordForm.formState.isSubmitting}
              >
                Ingresar
              </Button>
            </form>
          )}
      </Card>
    </main>
  );
};

export { SignInView };
