import React from 'react';
import {
  DefaultMantineColor,
  MantineProvider,
  MantineThemeOverride,
  Tuple
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';

type AppColorNames =
  | 'lapislazuli'
  | 'bleudefrance'
  | 'white'
  | 'black'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, Tuple<string, 10>>;
  }
}

const AppTheme: MantineThemeOverride = {
  colors: {
    white: new Array(10).fill('#ffffff') as Tuple<string, 10>,
    black: new Array(10).fill('#000000') as Tuple<string, 10>,
    lapislazuli: [
      '#e6f2ff',
      '#c1d6f1',
      '#9dbae3',
      '#779ed4',
      '#5283c7',
      '#33609E',
      '#2a5288',
      '#1c3b62',
      '#0d233e',
      '#000d1b'
    ],
    bleudefrance: [
      '#e3f2ff',
      '#b8dafa',
      '#8bc3f7',
      '#5fabf6',
      '#3d94f5',
      '#2f7bdc',
      '#2460ab',
      '#184479',
      '#0b2949',
      '#000e1a'
    ]
  },
  primaryColor: 'lapislazuli',
  fontFamily: '"Montserrat", sans-serif',
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colors.bleudefrance[0]
    }
  })
};

const MantineLayoutProvider = ({
  children
}: {
  children?: React.ReactNode;
}) => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={AppTheme}>
      <DatesProvider
        settings={{ firstDayOfWeek: 0, weekendDays: [0, 6], locale: 'es' }}
      >
        <Notifications />
        {children}
      </DatesProvider>
    </MantineProvider>
  );
};

export { MantineLayoutProvider };
