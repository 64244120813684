// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H3DoKTB5m4Wkj3UmIIB9{display:flex;flex-flow:column nowrap;row-gap:10px}.H3DoKTB5m4Wkj3UmIIB9>span{width:100%;text-align:center}.H3DoKTB5m4Wkj3UmIIB9 .slgVmrsHjdBOCPjH1KlJ{display:grid;grid-template-columns:repeat(4, 1fr);grid-auto-rows:min-content;align-items:start;gap:10px}.H3DoKTB5m4Wkj3UmIIB9 .slgVmrsHjdBOCPjH1KlJ>span{grid-area:1/1/2/5}", "",{"version":3,"sources":["webpack://./src/features/associate/views/InformationUpdateView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,uBAAA,CACA,YAAA,CAEA,2BACE,UAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CAEA,oCAAA,CACA,0BAAA,CAEA,iBAAA,CACA,QAAA,CAEA,iDACE,iBAAA","sourcesContent":[".main {\n  display: flex;\n\n  flex-flow: column nowrap;\n  row-gap: 10px;\n\n  > span{\n    width: 100%;\n    text-align: center;\n  }\n\n  .formCard {\n    display: grid;\n\n    grid-template-columns: repeat(4, 1fr);\n    grid-auto-rows: min-content;\n    \n    align-items: start;\n    gap: 10px;\n\n    > span {\n      grid-area: 1 / 1 / 2 / 5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "H3DoKTB5m4Wkj3UmIIB9",
	"formCard": "slgVmrsHjdBOCPjH1KlJ"
};
export default ___CSS_LOADER_EXPORT___;
