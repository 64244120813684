import { Outlet } from 'react-router-dom';

import { BaseLayout } from './common/layouts/index.ts';

const AppLayout = () => {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export { AppLayout };
