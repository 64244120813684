import { Button } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';
import { z } from 'zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Select, NumberInput } from '@/common/components/index.ts';
import { IAPIUserI13lFinancialRelation } from '@zudi/types';
import { useForm } from '@/common/hooks/index.ts';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';
import {
  deleteFinancialRelation,
  getFinancialRelation,
  patchFinancialRelation,
  postFinancialRelation
} from '@/common/queries/finacial_relations.ts';

import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';
import { Countries, Currencies } from './const.ts';

const countryOptions = Object.entries(Countries).map(([value, label]) => ({
  label,
  value
}));

const currencyOptions = Object.entries(Currencies).map(([value, label]) => ({
  label,
  value
}));

const FinancialRelationFormSchema = z.object({
  relationCurrency: z.string().min(3, 'La moneda es requerida'),
  relationCountry: z.string().min(2, 'El pais es requerido'),
  relationAmountAvg: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number)))
});

const FinancialRelationForm = ({
  id,
  capabilities
}: {
  id?: string;
  capabilities: ('create' | 'save' | 'delete')[];
}) => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const resource = useQuery({
    queryKey: ['financial_relations', { id }],
    queryFn: async (qfnContext) => {
      if (!id) {
        throw new Error('Id is required for the fetch operation');
      }
      return getFinancialRelation(id, { qfnContext, apiProvider });
    },
    enabled: !!id
  });

  const postMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIUserI13lFinancialRelation) => {
      await requestUserDataAuthorization();
      return await postFinancialRelation(data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(
        ['financial_relations', { id: response.id }],
        response
      );

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });

      dataForm.reset();
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const patchMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIUserI13lFinancialRelation) => {
      await requestUserDataAuthorization();
      return await patchFinancialRelation(data.id, data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(
        ['financial_relations', { id: response.id }],
        response
      );

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const deleteMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Eliminando Relacion Financiera',
        message: 'Se esta eliminando el elemento',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIUserI13lFinancialRelation) => {
      await requestUserDataAuthorization();
      return await deleteFinancialRelation(data.id, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (_, data) => {
      queryClient.removeQueries({
        exact: true,
        queryKey: ['financial_relations', { id: data.id }]
      });
      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha eliminado la relacion financiera correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  });

  const dataForm = useForm<IAPIUserI13lFinancialRelation>({
    defaultValues: resource.data,
    resolver: zodResolver(FinancialRelationFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(
    async (data) => {
      if (id) {
        await patchMut.mutateAsync({ ...data, id });
      } else {
        await postMut.mutateAsync(data);
      }
    },
    async (values) => {
      console.log(values);
    }
  );

  const onDelete = async () => {
    if (!resource.data) {
      throw new Error('Original data has not been loaded');
    }

    await deleteMut.mutateAsync(resource.data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar Relacion Financiera`
            : `Editar ${resource.data?.relationCountry}`
        }
        actions={
          <>
            {capabilities.includes('save') && (
              <Button
                type="submit"
                loading={patchMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Guardar
              </Button>
            )}
            {capabilities.includes('delete') && (
              <Button
                onClick={onDelete}
                loading={deleteMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Eliminar
              </Button>
            )}
            {capabilities.includes('create') && (
              <Button
                type="submit"
                loading={postMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Agregar
              </Button>
            )}
          </>
        }
      >
        <Select<IAPIUserI13lFinancialRelation>
          control={dataForm.control}
          label="Pais"
          data={countryOptions}
          name="relationCountry"
          searchable
          allowDeselect
          nothingFound="Sin resultados..."
        />

        <Select<IAPIUserI13lFinancialRelation>
          control={dataForm.control}
          label="Tipo de Moneda"
          data={currencyOptions}
          name="relationCurrency"
          searchable
          allowDeselect
          nothingFound="Sin resultados..."
        />

        <NumberInput<IAPIUserI13lFinancialRelation>
          control={dataForm.control}
          label="Promedio total"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="relationAmountAvg"
        />
      </FormCard>
    </form>
  );
};

export { FinancialRelationForm };
