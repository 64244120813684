import { IAPIEmployerBranch } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getEmployerBranches = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIEmployerBranch[];
  }>(`/employers/branches`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

export { getEmployerBranches };
