import { IAPIIndividual } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getIndividuals = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividual[];
  }>(`/individuals`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const getIndividual = async (id: string, requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividual;
  }>(`/individuals/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const postIndividual = async (
  data: IAPIIndividual,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.post<{
    data: IAPIIndividual;
  }>(`/individuals`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchIndividual = async (
  id: string,
  data: IAPIIndividual,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIIndividual;
  }>(`/individuals/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const deleteIndividual = async (
  id: string,
  requestContext: IRequestContext
) => {
  await requestContext.apiProvider.apiAuthenticated.delete<never>(
    `/individuals/${id}`,
    {
      signal: requestContext.qfnContext?.signal
    }
  );
};

export {
  getIndividuals,
  getIndividual,
  postIndividual,
  patchIndividual,
  deleteIndividual
};
