const BankSwiftCodes: Record<string, { bank: string; city: string }> = {
  ACVRCOB1: { bank: 'ACCIONES Y VALORES S.A.', city: 'BOGOTA' },
  ACDPCOB1: {
    bank: 'ADMINISTRADORA COLOMBIANA DE PENSIONES - COLPENSIONES',
    city: 'BOGOTA'
  },
  AFIUCOBB: { bank: 'ALIANZA FIDUCIARIA', city: 'BOGOTA' },
  AVCBCOB1: {
    bank: 'ALIANZA VALORES COMISIONISTA DE BOLSA S.A',
    city: 'BOGOTA'
  },
  HUBCCOBB: { bank: 'ALLIANCE ENTERPRISE S.A.S.', city: 'BOGOTA' },
  FPVCCOBB: { bank: 'ALLIANZ SEGUROS DE VIDA S.A.', city: 'BOGOTA' },
  GEXICOBB: { bank: 'ALMACENES EXITO S.A. (GRUPO EXITO)', city: 'ENVIGADO' },
  AIPACOBB: { bank: 'ALPINA PRODUCTOS ALIMENTICIOS SA BIC', city: 'BOGOTA' },
  CCAICOBB: { bank: 'BANCO AGRARIO DE COLOMBIA S.A.', city: 'BOGOTA' },
  GEROCOBB: {
    bank: 'BBVA COLOMBIA S.A.',
    city: 'BOGOTA'
  },
  CASOCOBB: { bank: 'BANCO CAJA SOCIAL', city: 'BOGOTA' },
  BAVICOBB: { bank: 'BANCO COMERCIAL AV VILLAS S.A.', city: 'BOGOTA' },
  CAFECOBB: { bank: 'BANCO DAVIVIENDA S.A.', city: 'BOGOTA' },
  BBOGCOBB: { bank: 'BANCO DE BOGOTA', city: 'BOGOTA' },
  BCEXCOBB: {
    bank: 'BANCO DE COMERCIO EXTERIOR DE COLOMBIA S.A.',
    city: 'BOGOTA'
  },
  BREPCOBB: { bank: 'BANCO DE LA REPUBLICA', city: 'BOGOTA' },
  OCCICOBC: { bank: 'BANCO DE OCCIDENTE', city: 'CALI' },
  FALACOBB: { bank: 'BANCO FALABELLA S.A.', city: 'BOGOTA' },
  BSUDCOBB: { bank: 'BANCO GNB SUDAMERIS', city: 'BOGOTA' },
  COLOCOBM: { bank: 'BANCOLOMBIA S.A.', city: 'MEDELLIN' },
  BCOLOCOB: { bank: 'BBVA COLOMBIA S.A.', city: 'BOGOTA' },
  CITICOBB: { bank: 'CITIBANK N.A.', city: 'BOGOTA' },
  COLTCOBB: { bank: 'COLTEFINANCIERA S.A.', city: 'BOGOTA' },
  CONFIS: { bank: 'CONFIDECOLOMBIA S.A.', city: 'BOGOTA' },
  CORFICOBB: { bank: 'CORFICOLOMBIANA S.A.', city: 'BOGOTA' },
  DPLTCOBB: { bank: 'DAVIPLATA S.A.', city: 'BOGOTA' },
  FINDCOBB: { bank: 'BANCO FINANDINA', city: 'BOGOTA' },
  GFCOCOBB: { bank: 'GIROS Y FINANZAS C.F.', city: 'MEDELLIN' },
  BCTOCOBB: { bank: 'HELM BANK S.A.', city: 'BOGOTA' },
  BICCOBCX: { bank: 'ITAU CORPBANCA COLOMBA', city: 'BOGOTA' },
  CHASCOBX: { bank: 'JPMORGAN CHASE BANK, N.A.', city: 'BOGOTA' },
  COLPCOBB: { bank: 'SCOTIABANK COLPATRIA', city: 'BOGOTA' },
  COOMCOBB: { bank: 'BANCOOMEVA S.A.', city: 'CALI' },
  WWWWCOBB: { bank: 'BANCO W S.A.', city: 'BOGOTA' },
  PICHCOBB: { bank: 'BANCO PICHINCHA S.A.', city: 'BOGOTA' },
  BSCHCOBB: { bank: 'BANCO SANTANDER COLOMBIA S.A.', city: 'BOGOTA' },
  PRCBCOBB: { bank: 'BANCO PROCREDIT COLOMBIA', city: 'BOGOTA' },
  COOPCOBB: { bank: 'BANCO COOPERATIVO COOPCENTRAL', city: 'BOGOTA' },
  BAMICOBB: { bank: 'BANCAMIA S.A.', city: 'BOGOTA' },
  MCTICOBB: { bank: 'BANCO MULTIBANK S.A.', city: 'BOGOTA' },
  CPTCCOBB: { bank: 'COLPATRIA MULTIBANCA', city: 'BOGOTA' },
  CONFICOBM: { bank: 'CONFIAR COOPERATIVA FINANCIERA', city: 'MEDELLIN' },
  CORBCOBB: { bank: 'CORPBANCA S.A.', city: 'BOGOTA' },
  COTRCOBB: { bank: 'COTRAFA COOPERATIVA FINANCIERA', city: 'MEDELLIN' },
  DAVICOB: { bank: 'DAVIPLATA S.A.', city: 'BOGOTA' },
  DCVLCOBB: { bank: 'DECEVAL S.A.', city: 'BOGOTA' },
  FJURCOBB: { bank: 'FINANCIERA JURISCOOP', city: 'CALI' },
  GYFICOBB: { bank: 'GIROS Y FINANZAS C.F.', city: 'MEDELLIN' },
  NEQUICOBB: { bank: 'NEQUI', city: 'BOGOTA' },
  RAPIDCOBB: { bank: 'RAPPIDINERO', city: 'BOGOTA' },
  SERFCOBB: { bank: 'SERFINANSA S.A.', city: 'BARRANQUILLA' }
};

export { BankSwiftCodes };
