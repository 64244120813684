enum DeploymentStage {
  PRODUCTION = 'production',
  STAGING = 'staging',
  ALPHA = 'alpha'
}

const DeploymentProfile = 'femcng';
const DeploymentFQDN = 'femcng.com';

const DeploymentRegions: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'us-east-1',
  [DeploymentStage.STAGING]: 'us-east-2',
  [DeploymentStage.ALPHA]: 'us-west-1'
};

const DeploymentDomains: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'transactional.femcng.com',
  [DeploymentStage.STAGING]: 'transactional-staging.femcng.com',
  [DeploymentStage.ALPHA]: 'transactional-alpha.femcng.com'
};

const ApiDeploymentDomains: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'api.transactional.femcng.com',
  [DeploymentStage.STAGING]: 'api.transactional-staging.femcng.com',
  [DeploymentStage.ALPHA]: 'api.transactional-alpha.femcng.com'
};

const isDeploymentStage = (value: unknown): value is DeploymentStage =>
  typeof value === 'string' &&
  Object.values(DeploymentStage).includes(value as any);

export {
  DeploymentStage,
  DeploymentProfile,
  DeploymentFQDN,
  DeploymentRegions,
  DeploymentDomains,
  ApiDeploymentDomains,
  isDeploymentStage
};
