import React, { ComponentProps } from 'react';
import { Select as MantineSelect } from '@mantine/core';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';
import lodash from 'lodash';

const Select = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    ComponentProps<typeof MantineSelect>
) => {
  const { field, formState } = useController(props);

  const error = lodash.get(formState.errors, field.name)?.message as
    | string
    | undefined;

  return (
    <MantineSelect
      {...props}
      {...field}
      value={
        field.value === true
          ? 'true'
          : field.value === false
          ? 'false'
          : field.value === undefined
          ? null
          : field.value
      }
      error={error}
      onChange={(value) => {
        if (value === 'true') {
          return field.onChange(true as any);
        }
        if (value === 'false') {
          return field.onChange(false as any);
        }
        return field.onChange(value as any);
      }}
    />
  );
};

export { Select };
