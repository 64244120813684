// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DRXU_C6eITYJKh2er6NR{position:fixed;bottom:25px;left:25px;cursor:pointer}.DRXU_C6eITYJKh2er6NR svg{width:75px;height:75px}", "",{"version":3,"sources":["webpack://./src/common/components/WhatsappLinkButton/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,SAAA,CAEA,cAAA,CAEA,0BACE,UAAA,CACA,WAAA","sourcesContent":[".main {\n  position: fixed;\n  bottom: 25px;\n  left: 25px;\n\n  cursor: pointer;\n\n  svg {\n    width: 75px;\n    height: 75px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "DRXU_C6eITYJKh2er6NR"
};
export default ___CSS_LOADER_EXPORT___;
