import { IAPIUserI13lFinancialRelation } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getFinancialRelations = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIUserI13lFinancialRelation[];
  }>(`/financial_relations`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const getFinancialRelation = async (
  id: string,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIUserI13lFinancialRelation;
  }>(`/financial_relations/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const postFinancialRelation = async (
  data: IAPIUserI13lFinancialRelation,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.post<{
    data: IAPIUserI13lFinancialRelation;
  }>(`/financial_relations`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchFinancialRelation = async (
  id: string,
  data: IAPIUserI13lFinancialRelation,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIUserI13lFinancialRelation;
  }>(`/financial_relations/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const deleteFinancialRelation = async (
  id: string,
  requestContext: IRequestContext
) => {
  await requestContext.apiProvider.apiAuthenticated.delete<never>(
    `/financial_relations/${id}`,
    {
      signal: requestContext.qfnContext?.signal
    }
  );
};

export {
  getFinancialRelations,
  getFinancialRelation,
  postFinancialRelation,
  patchFinancialRelation,
  deleteFinancialRelation
};
