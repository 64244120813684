import { IAPIIndividualPet } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getPets = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualPet[];
  }>(`/pets`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const getPet = async (id: string, requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualPet;
  }>(`/pets/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const postPet = async (
  data: IAPIIndividualPet,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.post<{
    data: IAPIIndividualPet;
  }>(`/pets`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchPet = async (
  id: string,
  data: IAPIIndividualPet,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIIndividualPet;
  }>(`/pets/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const deletePet = async (id: string, requestContext: IRequestContext) => {
  await requestContext.apiProvider.apiAuthenticated.delete<never>(
    `/pets/${id}`,
    {
      signal: requestContext.qfnContext?.signal
    }
  );
};

export { getPets, getPet, postPet, patchPet, deletePet };
