import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';
import { Button, TextInput } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';
import { useForm } from '@/common/hooks/useForm.ts';
import { NumberInput, Select } from '@/common/components/index.ts';
import { VehicleType } from '@zudi/lib';
import { IAPIIndividualVehicle } from '@zudi/types';
import {
  deleteVehicle,
  getVehicle,
  patchVehicle,
  postVehicle
} from '@/common/queries/vehicles.ts';

import { FormCard } from '../index.tsx';

import styles from './component.module.scss';

const VehicleFormSchema = z.object({
  plateNumber: z
    .string()
    .regex(/^[A-Z]{3}-?\d{3}$|^[A-Z]{3}-?\d{2}[A-Z]$/, 'Placa invalida.'),
  vehicleType: z.string(),
  vehiclePriceAvg: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  brandName: z.string().min(2, 'La marca debe tener mas de dos caracteres'),
  brandLine: z.string().min(2, 'La linea debe tener mas de dos caracteres'),
  brandModel: z.string().min(2, 'El modelo debe tener mas de dos caracteres')
});

const VehicleForm = ({
  id,
  capabilities
}: {
  id?: string;
  capabilities: ('create' | 'save' | 'delete')[];
}) => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const resource = useQuery({
    queryKey: ['vehicles', { id }],
    queryFn: async (qfnContext) => {
      if (!id) {
        throw new Error('Id is required for the fetch operation');
      }
      return getVehicle(id, { qfnContext, apiProvider });
    },
    enabled: !!id
  });

  const postMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });
      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualVehicle) => {
      await requestUserDataAuthorization();
      return await postVehicle(data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['vehicles', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });

      dataForm.reset();
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const patchMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualVehicle) => {
      await requestUserDataAuthorization();
      return await patchVehicle(data.id, data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['vehicles', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const deleteMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Eliminando Vehículo',
        message: 'Se esta eliminando el elemento',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualVehicle) => {
      await requestUserDataAuthorization();
      return await deleteVehicle(data.id, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (_, data) => {
      queryClient.removeQueries({
        exact: true,
        queryKey: ['vehicles', { id: data.id }]
      });
      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha eliminado el vehículo correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  });

  const dataForm = useForm<IAPIIndividualVehicle>({
    defaultValues: resource.data,
    resolver: zodResolver(VehicleFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(
    async (data) => {
      if (id) {
        await patchMut.mutateAsync({ ...data, id });
      } else {
        await postMut.mutateAsync(data);
      }
    },
    async (values) => {
      console.log(values);
    }
  );

  const onDelete = async () => {
    if (!resource.data) {
      throw new Error('Original data has not been loaded');
    }

    await deleteMut.mutateAsync(resource.data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar vehículo`
            : `Editar ${resource.data?.brandName} ${resource.data?.plateNumber}`
        }
        actions={
          <>
            {capabilities.includes('save') && (
              <Button
                type="submit"
                loading={patchMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Guardar
              </Button>
            )}
            {capabilities.includes('delete') && (
              <Button
                onClick={onDelete}
                loading={deleteMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Eliminar
              </Button>
            )}
            {capabilities.includes('create') && (
              <Button
                type="submit"
                loading={postMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Agregar
              </Button>
            )}
          </>
        }
      >
        <TextInput
          label="Placa del vehículo"
          {...dataForm.getInputProps('plateNumber', {})}
        />
        <Select<IAPIIndividualVehicle>
          control={dataForm.control}
          label="Tipo de vehículo"
          data={[
            {
              label: 'MOTOCICLETA',
              value: VehicleType.MOTOCICLETA
            },
            {
              label: 'AUTOMOVIL',
              value: VehicleType.AUTOMOVIL
            },
            {
              label: 'CAMPERO',
              value: VehicleType.CAMPERO
            },
            {
              label: 'CAMIONETA',
              value: VehicleType.CAMIONETA
            },
            {
              label: 'OTRO',
              value: VehicleType.OTRO
            }
          ]}
          name="vehicleType"
        />
        <NumberInput<IAPIIndividualVehicle>
          control={dataForm.control}
          label="Precio del vehículo"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="vehiclePriceAvg"
        />
        <TextInput
          label="Marca el vehículo"
          {...dataForm.getInputProps('brandName', {})}
        />
        <TextInput
          label="Linea del vehículo"
          {...dataForm.getInputProps('brandLine', {})}
        />
        <TextInput
          label="Modelo del vehículo"
          {...dataForm.getInputProps('brandModel', {})}
        />
      </FormCard>
    </form>
  );
};

export { VehicleForm };
