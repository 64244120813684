import React, { useEffect } from 'react';
import { Flex } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { Authenticated } from '@/common/components/index.ts';
import { useApiProvider, useHeaderProvider } from '@/common/providers/index.ts';
import { getIntegrationsUserStatementUrl } from '@/common/queries/integrations.ts';

import styles from './view.module.scss';
import { DashboardLink } from './components/index.ts';

const DashboardLinks = [
  <DashboardLink title="Reglamentación & Normatividad" url="/legal/docs" />,
  <DashboardLink
    title="Actualización de Datos"
    url="/associate/information/update"
  />
];

const DashboardView = () => {
  const { setTitle } = useHeaderProvider();
  const apiProvider = useApiProvider();

  useEffect(() => {
    setTitle('Dashboard');
  }, []);

  const integrationsUserStatementUrl = useQuery({
    queryKey: ['integrations', 'user_statement_url'],
    queryFn: async (qfnContext) =>
      getIntegrationsUserStatementUrl({ qfnContext, apiProvider })
  });

  return (
    <Authenticated>
      <div className={styles.main}>
        <Flex gap={20} wrap={'wrap'}>
          {[
            ...DashboardLinks,
            integrationsUserStatementUrl.isLoading ? (
              <DashboardLink title="Estado de Cuenta" isLoading />
            ) : integrationsUserStatementUrl.data ? (
              <DashboardLink
                title="Estado de Cuenta"
                url={integrationsUserStatementUrl.data}
                isExternal
              />
            ) : undefined
          ]
            .filter((s): s is JSX.Element => Boolean(s))
            .map((element, idx) => (
              <React.Fragment key={idx}>{element}</React.Fragment>
            ))}
        </Flex>
      </div>
    </Authenticated>
  );
};

export { DashboardView };
