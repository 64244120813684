import { Button, TextInput } from '@mantine/core';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DateInput, NumberInput, Select } from '@/common/components/index.ts';
import { IAPIIndividual } from '@zudi/types';
import { useForm } from '@/common/hooks/index.ts';
import { DocumentType, EducationLevelType, MaritalStatusType } from '@zudi/lib';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';
import {
  deleteIndividual,
  getIndividual,
  patchIndividual,
  postIndividual
} from '@/common/queries/individuals.ts';

import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';

const BeneficiaryFormSchema = z.object({
  firstName: z.string().min(2, 'El nombre es requerido'),
  lastName: z.string().min(2, 'El apellido es requerido'),
  emailAddress: z.string().email().nullish(),
  gender: z.string().nullish(),
  phone: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  documentId: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  documentType: z.string(),
  documentIssuedAt: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  documentIssuedIn: z.string().nullish(),
  birthDate: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  birthCity: z.string().nullish(),
  maritalStatus: z.string().nullish(),
  educationLevel: z.string().nullish(),
  educationLevelFinished: z.boolean().nullish().default(false),
  residenceAddress: z.string().nullish(),
  residenceNeighbourhood: z.string().nullish(),
  /* residencePhone: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))), */
  residenceType: z.string().nullish(),
  residenceOwnershipType: z.string().nullish(),
  residencePermanencyType: z.string().nullish(),
  residenceStratum: z.number().nullish(),
  householdHead: z.boolean().nullish().default(false)
});

const BeneficiaryForm = ({
  id,
  capabilities
}: {
  id?: string;
  capabilities: ('create' | 'save' | 'delete')[];
}) => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const resource = useQuery({
    queryKey: ['individuals', { id }],
    queryFn: async (qfnContext) => {
      if (!id) {
        throw new Error('Id is required for the fetch operation');
      }
      return getIndividual(id, { qfnContext, apiProvider });
    },
    enabled: !!id
  });

  const postMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividual) => {
      await requestUserDataAuthorization();
      return await postIndividual(data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['individuals', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });

      dataForm.reset();
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const patchMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividual) => {
      await requestUserDataAuthorization();
      return await patchIndividual(data.id, data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['individuals', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const deleteMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Eliminando Beneficiario',
        message: 'Se esta eliminando el elemento',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividual) => {
      await requestUserDataAuthorization();
      return await deleteIndividual(data.id, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (_, data) => {
      queryClient.removeQueries({
        exact: true,
        queryKey: ['individuals', { id: data.id }]
      });
      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha eliminado el beneficiario correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  });

  const dataForm = useForm<IAPIIndividual>({
    defaultValues: resource.data,
    resolver: zodResolver(BeneficiaryFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(
    async (data) => {
      if (id) {
        await patchMut.mutateAsync({ ...data, id });
      } else {
        await postMut.mutateAsync(data);
      }
    },
    async (values) => {
      console.log(values);
    }
  );

  const onDelete = async () => {
    if (!resource.data) {
      throw new Error('Original data has not been loaded');
    }

    await deleteMut.mutateAsync(resource.data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar Beneficiario`
            : `Editar ${resource.data?.firstName} ${resource.data?.lastName}`
        }
        actions={
          <>
            {capabilities.includes('save') && (
              <Button
                type="submit"
                loading={patchMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Guardar
              </Button>
            )}
            {capabilities.includes('delete') && (
              <Button
                onClick={onDelete}
                loading={deleteMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Eliminar
              </Button>
            )}
            {capabilities.includes('create') && (
              <Button
                type="submit"
                loading={postMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Agregar
              </Button>
            )}
          </>
        }
      >
        <TextInput
          label="Nombres"
          {...dataForm.getInputProps('firstName', {})}
        />
        <TextInput
          label="Apellidos"
          {...dataForm.getInputProps('lastName', {})}
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Tipo de Documento"
          data={[
            {
              label: 'Cédula de Ciudadanía',
              value: DocumentType.CEDULA_CIUDADANIA
            },
            {
              label: 'Cédula de Extranjería',
              value: DocumentType.CEDULA_EXTRANJERIA
            },
            {
              label: 'Tarjeta de Identidad',
              value: DocumentType.TARJETA_IDENTIDAD
            },
            {
              label: 'Registro Civil',
              value: DocumentType.REGISTRO_CIVIL
            }
          ]}
          name="documentType"
        />
        <NumberInput<IAPIIndividual>
          control={dataForm.control}
          label="No. Documento"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="documentId"
        />
        <DateInput<IAPIIndividual>
          control={dataForm.control}
          label="Fecha de Nacimiento"
          name="birthDate"
        />
        <TextInput
          label="Lugar de Nacimiento"
          {...dataForm.getInputProps('birthCity', {})}
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Estado Civil"
          data={[
            { label: 'Soltero(a)', value: MaritalStatusType.SOLTERO },
            { label: 'Unión Libre', value: MaritalStatusType.UNION_LIBRE },
            { label: 'Casado(a)', value: MaritalStatusType.CASADO }
          ]}
          name="maritalStatus"
        />
        <NumberInput<IAPIIndividual>
          control={dataForm.control}
          label="Teléfono"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="phone"
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Nivel de Estudios"
          data={[
            { label: 'Primaria', value: EducationLevelType.PRIMARIA },
            { label: 'Secundaria', value: EducationLevelType.SECUNDARIA },
            { label: 'Universitario', value: EducationLevelType.UNIVERSITARIA },
            { label: 'Postgrado', value: EducationLevelType.POSTGRADO },
            { label: 'Master', value: EducationLevelType.MASTER },
            { label: 'Doctorado', value: EducationLevelType.DOCTORADO }
          ]}
          name="educationLevel"
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Nivel de Estudios Completos? (Si/No)"
          defaultValue={''}
          data={[
            { label: 'SI', value: 'true' },
            { label: 'NO', value: 'false' }
          ]}
          name="educationLevelFinished"
        />
      </FormCard>
    </form>
  );
};

export { BeneficiaryForm };
