import React, { ComponentProps } from 'react';
import { DateInput as MantineDateInput } from '@mantine/dates';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';
import lodash from 'lodash';

const DateInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    ComponentProps<typeof MantineDateInput>
) => {
  const { field, formState } = useController(props);

  const error = lodash.get(formState.errors, field.name)?.message as
    | string
    | undefined;

  return (
    <MantineDateInput
      {...props}
      {...field}
      error={error}
      value={
        field.value
          ? new Date(field.value)
          : field.value === undefined
          ? null
          : field.value
      }
      onChange={(value) => field.onChange(value as any)}
    />
  );
};

export { DateInput };
