const Countries = {
  AF: 'Afganistán',
  AL: 'Albania',
  DE: 'Alemania',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguila',
  AQ: 'Antártida',
  AG: 'Antigua y Barbuda',
  SA: 'Arabia Saudita',
  DZ: 'Argelia',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaiyán',
  BS: 'Bahamas',
  BD: 'Bangladés',
  BB: 'Barbados',
  BH: 'Baréin',
  BE: 'Bélgica',
  BZ: 'Belice',
  BJ: 'Benín',
  BM: 'Bermudas',
  BY: 'Bielorrusia',
  MM: 'Birmania',
  BO: 'Bolivia',
  BA: 'Bosnia y Herzegovina',
  BW: 'Botsuana',
  BR: 'Brasil',
  BN: 'Brunéi',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  BT: 'Bután',
  CV: 'Cabo Verde',
  KH: 'Camboya',
  CM: 'Camerún',
  CA: 'Canadá',
  QA: 'Catar',
  EA: 'Ceuta y Melilla',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CY: 'Chipre',
  VA: 'Ciudad del Vaticano',
  CO: 'Colombia',
  KM: 'Comoras',
  CG: 'Congo',
  KP: 'Corea del Norte',
  KR: 'Corea del Sur',
  CR: 'Costa Rica',
  CI: 'Costa de Marfil',
  HR: 'Croacia',
  CU: 'Cuba',
  CW: 'Curazao',
  DG: 'Diego García',
  DK: 'Dinamarca',
  DM: 'Dominica',
  EC: 'Ecuador',
  EG: 'Egipto',
  SV: 'El Salvador',
  AE: 'Emiratos Árabes Unidos',
  ER: 'Eritrea',
  SK: 'Eslovaquia',
  SI: 'Eslovenia',
  ES: 'España',
  US: 'Estados Unidos',
  EE: 'Estonia',
  SZ: 'Esuatini',
  ET: 'Etiopía',
  PH: 'Filipinas',
  FI: 'Finlandia',
  FJ: 'Fiyi',
  FR: 'Francia',
  GA: 'Gabón',
  GM: 'Gambia',
  GE: 'Georgia',
  GS: 'Georgia del Sur e Islas Sandwich del Sur',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Granada',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GP: 'Guadalupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GF: 'Guayana Francesa',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bisáu',
  GQ: 'Guinea Ecuatorial',
  GY: 'Guyana',
  HT: 'Haití',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungría',
  IN: 'India',
  ID: 'Indonesia',
  IQ: 'Irak',
  IR: 'Irán',
  IE: 'Irlanda',
  BV: 'Isla Bouvet',
  CX: 'Isla Christmas',
  CP: 'Isla Clipperton',
  AC: 'Isla de la Ascensión',
  IM: 'Isla de Man',
  NF: 'Isla Norfolk',
  IS: 'Islandia',
  AX: 'Islas Åland',
  KY: 'Islas Caimán',
  IC: 'Islas Canarias',
  CC: 'Islas Cocos',
  CK: 'Islas Cook',
  FO: 'Islas Feroe',
  FK: 'Islas Malvinas',
  MP: 'Islas Marianas del Norte',
  MH: 'Islas Marshall',
  UM: 'Islas menores alejadas de EE. UU.',
  PN: 'Islas Pitcairn',
  SB: 'Islas Salomón',
  TC: 'Islas Turcas y Caicos',
  VG: 'Islas Vírgenes Británicas',
  VI: 'Islas Vírgenes de los Estados Unidos',
  IL: 'Israel',
  IT: 'Italia',
  JM: 'Jamaica',
  JP: 'Japón',
  JE: 'Jersey',
  JO: 'Jordania',
  KZ: 'Kazajistán',
  KE: 'Kenia',
  KG: 'Kirguistán',
  KI: 'Kiribati',
  XK: 'Kosovo',
  KW: 'Kuwait',
  LA: 'Laos',
  LS: 'Lesoto',
  LV: 'Letonia',
  LB: 'Líbano',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  MO: 'Macao',
  MG: 'Madagascar',
  MY: 'Malasia',
  MW: 'Malaui',
  MV: 'Maldivas',
  ML: 'Malí',
  MT: 'Malta',
  MA: 'Marruecos',
  MQ: 'Martinica',
  MU: 'Mauricio',
  MR: 'Mauritania',
  YT: 'Mayotte',
  FM: 'Micronesia',
  MD: 'Moldavia',
  MC: 'Mónaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NI: 'Nicaragua',
  NE: 'Níger',
  NG: 'Nigeria',
  NU: 'Niue',
  NO: 'Noruega',
  NC: 'Nueva Caledonia',
  NZ: 'Nueva Zelanda',
  OM: 'Omán',
  NL: 'Países Bajos',
  PK: 'Pakistán',
  PW: 'Palaos',
  PS: 'Palestina',
  PA: 'Panamá',
  PG: 'Papúa Nueva Guinea',
  PY: 'Paraguay',
  PE: 'Perú',
  PF: 'Polinesia Francesa',
  PL: 'Polonia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  GB: 'Reino Unido',
  CF: 'República Centroafricana',
  CZ: 'República Checa',
  MK: 'República de Macedonia',
  ZA: 'República de Sudáfrica',
  DO: 'República Dominicana',
  RE: 'Reunión',
  RW: 'Ruanda',
  RO: 'Rumania',
  RU: 'Rusia',
  EH: 'Sáhara Occidental',
  WS: 'Samoa',
  AS: 'Samoa Americana',
  BL: 'San Bartolomé',
  KN: 'San Cristóbal y Nieves',
  SM: 'San Marino',
  MF: 'San Martín',
  PM: 'San Pedro y Miquelón',
  VC: 'San Vicente y las Granadinas',
  SH: 'Santa Elena',
  LC: 'Santa Lucía',
  ST: 'Santo Tomé y Príncipe',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leona',
  SG: 'Singapur',
  SX: 'Sint Maarten',
  SY: 'Siria',
  SO: 'Somalia',
  LK: 'Sri Lanka',
  SD: 'Sudán',
  SS: 'Sudán del Sur',
  SE: 'Suecia',
  CH: 'Suiza',
  SR: 'Surinam',
  SJ: 'Svalbard y Jan Mayen',
  TH: 'Tailandia',
  TW: 'Taiwán',
  TZ: 'Tanzania',
  TJ: 'Tayikistán',
  IO: 'Territorio Británico del Océano Índico',
  TF: 'Territorios Australes Franceses',
  TL: 'Timor Oriental',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad y Tobago',
  TN: 'Túnez',
  TM: 'Turkmenistán',
  TR: 'Turquía',
  TV: 'Tuvalu',
  UA: 'Ucrania',
  UG: 'Uganda',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  WF: 'Wallis y Futuna',
  YE: 'Yemen',
  DJ: 'Yibuti',
  ZM: 'Zambia',
  ZW: 'Zimbabue'
};

const Currencies = {
  AED: 'Dirham de los Emiratos Árabes Unidos',
  AFN: 'Afgani afgano',
  ALL: 'Lek albanés',
  AMD: 'Dram armenio',
  ANG: 'Florín antillano neerlandés',
  AOA: 'Kwanza angoleño',
  ARS: 'Peso argentino',
  AUD: 'Dólar australiano',
  AWG: 'Florín arubeño',
  AZN: 'Manat azerbaiyano',
  BAM: 'Marco convertible de Bosnia-Herzegovina',
  BBD: 'Dólar barbadense',
  BDT: 'Taka de Bangladesh',
  BGN: 'Lev búlgaro',
  BHD: 'Dinar bahreiní',
  BIF: 'Franco burundés',
  BMD: 'Dólar bermudeño',
  BND: 'Dólar de Brunéi',
  BOB: 'Boliviano',
  BOV: 'MVDOL boliviano',
  BRL: 'Real brasileño',
  BSD: 'Dólar bahameño',
  BTN: 'Ngultrum butanés',
  BWP: 'Pula botsuano',
  BYN: 'Rublo bielorruso',
  BZD: 'Dólar beliceño',
  CAD: 'Dólar canadiense',
  CDF: 'Franco congoleño',
  CHE: 'Euro WIR',
  CHF: 'Franco suizo',
  CHW: 'Franco WIR',
  CLF: 'Unidad de Fomento chilena',
  CLP: 'Peso chileno',
  CNY: 'Yuan renminbi chino',
  COP: 'Peso colombiano',
  COU: 'Unidad de Valor Real colombiana',
  CRC: 'Colón costarricense',
  CUC: 'Peso cubano convertible',
  CUP: 'Peso cubano',
  CVE: 'Escudo caboverdiano',
  CZK: 'Corona checa',
  DJF: 'Franco yibutiano',
  DKK: 'Corona danesa',
  DOP: 'Peso dominicano',
  DZD: 'Dinar argelino',
  EGP: 'Libra egipcia',
  ERN: 'Nakfa eritreo',
  ETB: 'Birr etíope',
  EUR: 'Euro',
  FJD: 'Dólar fiyiano',
  FKP: 'Libra de las Islas Malvinas',
  GBP: 'Libra esterlina británica',
  GEL: 'Lari georgiano',
  GHS: 'Cedi ghanés',
  GIP: 'Libra de Gibraltar',
  GMD: 'Dalasi gambiano',
  GNF: 'Franco guineano',
  GTQ: 'Quetzal guatemalteco',
  GYD: 'Dólar guyanés',
  HKD: 'Dólar de Hong Kong',
  HNL: 'Lempira hondureño',
  HRK: 'Kuna croata',
  HTG: 'Gourde haitiano',
  HUF: 'Forinto húngaro',
  IDR: 'Rupia indonesia',
  ILS: 'Nuevo shéquel israelí',
  INR: 'Rupia india',
  IQD: 'Dinar iraquí',
  IRR: 'Rial iraní',
  ISK: 'Corona islandesa',
  JMD: 'Dólar jamaicano',
  JOD: 'Dinar jordano',
  JPY: 'Yen japonés',
  KES: 'Chelín keniano',
  KGS: 'Som kirguís',
  KHR: 'Riel camboyano',
  KID: 'Dólar de Kiribati',
  KMF: 'Franco comorense',
  KPW: 'Won norcoreano',
  KRW: 'Won surcoreano',
  KWD: 'Dinar kuwaití',
  KYD: 'Dólar de las Islas Caimán',
  KZT: 'Tenge kazajo',
  LAK: 'Kip laosiano',
  LBP: 'Libra libanesa',
  LKR: 'Rupia de Sri Lanka',
  LRD: 'Dólar liberiano',
  LSL: 'Loti lesothense',
  LYD: 'Dinar libio',
  MAD: 'Dirham marroquí',
  MDL: 'Leu moldavo',
  MGA: 'Ariary malgache',
  MKD: 'Denar macedonio',
  MMK: 'Kyat birmano',
  MNT: 'Tugrik mongol',
  MOP: 'Pataca de Macao',
  MRU: 'Ugía mauritana',
  MUR: 'Rupia de Mauricio',
  MVR: 'Rupia de Maldivas',
  MWK: 'Kwacha malauí',
  MXN: 'Peso mexicano',
  MXV: 'Unidad de Inversión mexicana',
  MYR: 'Ringgit malayo',
  MZN: 'Metical mozambiqueño',
  NAD: 'Dólar namibio',
  NGN: 'Naira nigeriano',
  NIO: 'Córdoba nicaragüense',
  NOK: 'Corona noruega',
  NPR: 'Rupia nepalesa',
  NZD: 'Dólar neozelandés',
  OMR: 'Rial omaní',
  PAB: 'Balboa panameño',
  PEN: 'Sol peruano',
  PGK: 'Kina de Papúa Nueva Guinea',
  PHP: 'Peso filipino',
  PKR: 'Rupia pakistaní',
  PLN: 'Zloty polaco',
  PYG: 'Guaraní paraguayo',
  QAR: 'Rial qatarí',
  RON: 'Leu rumano',
  RSD: 'Dinar serbio',
  RUB: 'Rublo ruso',
  RWF: 'Franco ruandés',
  SAR: 'Riyal saudí',
  SBD: 'Dólar de las Islas Salomón',
  SCR: 'Rupia seychellense',
  SDG: 'Libra sudanesa',
  SEK: 'Corona sueca',
  SGD: 'Dólar singapurense',
  SHP: 'Libra de Santa Elena',
  SLL: 'Leone de Sierra Leona',
  SOS: 'Chelín somalí',
  SRD: 'Dólar surinamés',
  SSP: 'Libra sursudanesa',
  STN: 'Dobra de Santo Tomé y Príncipe',
  SYP: 'Libra siria',
  SZL: 'Lilangeni suazi',
  THB: 'Baht tailandés',
  TJS: 'Somoni tayiko',
  TMT: 'Manat turcomano',
  TND: 'Dinar tunecino',
  TOP: "Pa'anga tongano",
  TRY: 'Lira turca',
  TTD: 'Dólar de Trinidad y Tobago',
  TWD: 'Dólar taiwanés',
  TZS: 'Chelín tanzano',
  UAH: 'Grivna ucraniana',
  UGX: 'Chelín ugandés',
  USD: 'Dólar estadounidense',
  USN: 'Dólar estadounidense (Día siguiente)',
  UYI: 'Peso uruguayo en unidades indexadas',
  UYU: 'Peso uruguayo',
  UYW: 'Unidad Previsional uruguaya',
  UZS: 'Som uzbeko',
  VES: 'Bolívar venezolano',
  VND: 'Dong vietnamita',
  VUV: 'Vatu vanuatuense',
  WST: 'Tala samoano',
  XAF: 'Franco CFA de África Central',
  XAG: 'Plata (una onza troy)',
  XAU: 'Oro (una onza troy)',
  XBA: 'Unidad compuesta europea',
  XBB: 'Unidad monetaria europea',
  XBC: 'Unidad de cuenta europea (XBC)',
  XBD: 'Unidad de cuenta europea (XBD)',
  XCD: 'Dólar del Caribe Oriental',
  XDR: 'Derechos especiales de giro',
  XOF: 'Franco CFA de África Occidental',
  XPD: 'Paladio (una onza troy)',
  XPF: 'Franco CFP',
  XPT: 'Platino (una onza troy)',
  XSU: 'Sucre',
  XTS: 'Código reservado para pruebas',
  XUA: 'Unidad de cuenta BAD (África)',
  XXX: 'Sin divisa',
  YER: 'Rial yemení',
  ZAR: 'Rand sudafricano',
  ZMW: 'Kwacha zambiano',
  ZWL: 'Dólar zimbabuense'
};

export { Currencies, Countries };
