import { QueryClient, QueryFilters, matchQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

const useQueriesData = <T = unknown>(
  filters: QueryFilters,
  queryClient: QueryClient
) => {
  const [queriesData, setQueriesData] = useState(
    queryClient.getQueriesData<T>(filters)
  );
  const filtersMemo = useMemo(() => ({ filters }), []);
  filtersMemo.filters = filters;

  useEffect(() => {
    queryClient.getQueryCache().subscribe((event) => {
      if (
        ['added', 'removed', 'updated'].includes(event.type as string) &&
        matchQuery(filtersMemo.filters, event.query)
      ) {
        setQueriesData(queryClient.getQueriesData<T>(filtersMemo.filters));
      }
    });
  }, [queryClient.getQueryCache()]);

  return queriesData;
};

export { useQueriesData };
