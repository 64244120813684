import { createProviderStateHook } from '@juandavidkincaid/utils';

import { HeaderProvider, HeaderProviderContext } from './provider.tsx';

const useHeaderProvider = createProviderStateHook(
  HeaderProvider,
  HeaderProviderContext
);

export { useHeaderProvider };
