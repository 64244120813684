import { IAPIUser } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getUser = async (id: string, requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIUser;
  }>(`/users/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchUser = async (
  id: string,
  data: IAPIUser,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIUser;
  }>(`/users/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

export { getUser, patchUser };
