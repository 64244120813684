import React, { useState } from 'react';

import { IHeaderProviderContext } from './types.ts';

const HeaderProviderContext =
  React.createContext<IHeaderProviderContext | null>(null);

const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState('');

  const providerContext: IHeaderProviderContext = {
    title,
    setTitle
  };

  return (
    <HeaderProviderContext.Provider value={providerContext}>
      {children}
    </HeaderProviderContext.Provider>
  );
};

export { HeaderProvider, HeaderProviderContext };
