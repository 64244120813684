import { Button, TextInput } from '@mantine/core';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DateInput } from '@/common/components/index.ts';
import { IAPIIndividualPet } from '@zudi/types';
import { useForm } from '@/common/hooks/index.ts';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';
import { deletePet, getPet, patchPet, postPet } from '@/common/queries/pets.ts';

import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';

const PetFormSchema = z.object({
  name: z.string().min(2, 'El nombre es requerido'),
  breed: z.string().min(2, 'El apellido es requerido'),
  birthDate: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null)
});

const PetForm = ({
  id,
  capabilities
}: {
  id?: string;
  capabilities: ('create' | 'save' | 'delete')[];
}) => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const resource = useQuery({
    queryKey: ['pets', { id }],
    queryFn: async (qfnContext) => {
      if (!id) {
        throw new Error('Id is required for the fetch operation');
      }
      return getPet(id, { qfnContext, apiProvider });
    },
    enabled: !!id
  });

  const postMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualPet) => {
      await requestUserDataAuthorization();
      return await postPet(data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['pets', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });

      dataForm.reset();
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const patchMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualPet) => {
      await requestUserDataAuthorization();
      return await patchPet(data.id, data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(['pets', { id: response.id }], response);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const deleteMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Eliminando Mascota',
        message: 'Se esta eliminando el elemento',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualPet) => {
      await requestUserDataAuthorization();
      return await deletePet(data.id, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (_, data) => {
      queryClient.removeQueries({
        exact: true,
        queryKey: ['pets', { id: data.id }]
      });
      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha eliminado la mascota correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  });

  const dataForm = useForm<IAPIIndividualPet>({
    defaultValues: resource.data,
    resolver: zodResolver(PetFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(async (data) => {
    if (id) {
      await patchMut.mutateAsync({ ...data, id });
    } else {
      await postMut.mutateAsync(data);
    }
  });

  const onDelete = async () => {
    if (!resource.data) {
      throw new Error('Original data has not been loaded');
    }

    await deleteMut.mutateAsync(resource.data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar Mascota`
            : `Editar ${resource.data?.name}`
        }
        actions={
          <>
            {capabilities.includes('save') && (
              <Button
                type="submit"
                loading={patchMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Guardar
              </Button>
            )}
            {capabilities.includes('delete') && (
              <Button
                onClick={onDelete}
                loading={deleteMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Eliminar
              </Button>
            )}
            {capabilities.includes('create') && (
              <Button
                type="submit"
                loading={postMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Agregar
              </Button>
            )}
          </>
        }
      >
        <TextInput label="Nombre" {...dataForm.getInputProps('name', {})} />
        <TextInput label="Raza" {...dataForm.getInputProps('breed', {})} />
        <DateInput<IAPIIndividualPet>
          control={dataForm.control}
          label="Fecha de Nacimiento"
          name="birthDate"
        />
      </FormCard>
    </form>
  );
};

export { PetForm };
