import { Button } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';
import { z } from 'zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Select, NumberInput } from '@/common/components/index.ts';
import { IAPIIndividualBankAccount } from '@zudi/types';
import { useForm } from '@/common/hooks/index.ts';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';
import {
  deleteBank,
  getBank,
  patchBank,
  postBank
} from '@/common/queries/banks.ts';
import { BankAccountType } from '@zudi/lib';

import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';
import { BankSwiftCodes } from './const.ts';

const bankOptions = Object.entries(BankSwiftCodes).map(([value, bankData]) => ({
  label: bankData.bank,
  value
}));

const BankAccountFormSchema = z.object({
  accountType: z.string().min(3, 'El tipo de cuenta es requerido'),
  accountNumber: z.coerce
    .number()
    .transform((number) => (number === null ? null : String(number))),
  accountBankId: z.string().min(2, 'El banco es requerido')
});

const BankAccountForm = ({
  id,
  capabilities
}: {
  id?: string;
  capabilities: ('create' | 'save' | 'delete')[];
}) => {
  const apiProvider = useApiProvider();
  const queryClient = useQueryClient();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const resource = useQuery({
    queryKey: ['bank_accounts', { id }],
    queryFn: async (qfnContext) => {
      if (!id) {
        throw new Error('Id is required for the fetch operation');
      }
      return getBank(id, { qfnContext, apiProvider });
    },
    enabled: !!id
  });

  const postMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualBankAccount) => {
      await requestUserDataAuthorization();
      return await postBank(data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(
        ['bank_accounts', { id: response.id }],
        response
      );

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });

      dataForm.reset();
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const patchMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Guardando Información',
        message: 'Se esta guardando la información en el servidor',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualBankAccount) => {
      await requestUserDataAuthorization();
      return await patchBank(data.id, data, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (response, data, context) => {
      queryClient.setQueryData(
        ['bank_accounts', { id: response.id }],
        response
      );

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const deleteMut = useMutation({
    onMutate: async () => {
      const loadingNotificationId = Math.random().toString();

      notifications.show({
        id: loadingNotificationId,
        color: 'bleudefrance',
        title: 'Eliminando Cuenta Bancaria',
        message: 'Se esta eliminando el elemento',
        loading: true,
        autoClose: false
      });

      return { loadingNotificationId };
    },
    mutationFn: async (data: IAPIIndividualBankAccount) => {
      await requestUserDataAuthorization();
      return await deleteBank(data.id, { apiProvider });
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return;
      }
      notifications.hide(context.loadingNotificationId);
    },
    onSuccess: async (_, data) => {
      queryClient.removeQueries({
        exact: true,
        queryKey: ['bank_accounts', { id: data.id }]
      });
      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha eliminado la cuenta bancaria',
        autoClose: 10000
      });
    },
    onError: async (error, data, context) => {
      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  });

  const dataForm = useForm<IAPIIndividualBankAccount>({
    defaultValues: resource.data,
    resolver: zodResolver(BankAccountFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(
    async (data) => {
      if (id) {
        await patchMut.mutateAsync({ ...data, id });
      } else {
        await postMut.mutateAsync(data);
      }
    },
    async (values) => {
      console.log(values);
    }
  );

  const onDelete = async () => {
    if (!resource.data) {
      throw new Error('Original data has not been loaded');
    }

    await deleteMut.mutateAsync(resource.data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar Relacion Financiera`
            : `Editar ${
                BankSwiftCodes[resource.data?.accountBankId ?? '']?.bank
              }`
        }
        actions={
          <>
            {capabilities.includes('save') && (
              <Button
                type="submit"
                loading={patchMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Guardar
              </Button>
            )}
            {capabilities.includes('delete') && (
              <Button
                onClick={onDelete}
                loading={deleteMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Eliminar
              </Button>
            )}
            {capabilities.includes('create') && (
              <Button
                type="submit"
                loading={postMut.isPending}
                sx={{ flexGrow: 1 }}
              >
                Agregar
              </Button>
            )}
          </>
        }
      >
        <Select<IAPIIndividualBankAccount>
          control={dataForm.control}
          label="Tipo de Cuenta"
          data={[
            { label: 'Ahorros', value: BankAccountType.AHORROS },
            { label: 'Corriente', value: BankAccountType.CORRIENTE }
          ]}
          name="accountType"
        />

        <Select<IAPIIndividualBankAccount>
          control={dataForm.control}
          label="Banco"
          data={bankOptions}
          name="accountBankId"
          searchable
          allowDeselect
          nothingFound="Sin resultados..."
        />

        <NumberInput<IAPIIndividualBankAccount>
          control={dataForm.control}
          label="No. Cuenta"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{4})+(?!\d))/g, '-')
              : ''
          }
          name="accountNumber"
        />
      </FormCard>
    </form>
  );
};

export { BankAccountForm };
