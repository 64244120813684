import {
  useForm as _useForm,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn
} from 'react-hook-form';
import lodash from 'lodash';

type ExtendedFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
> = {
  onSubmit: UseFormHandleSubmit<TFieldValues, TTransformedValues>;
  getInputProps: <
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>
  ) => UseFormRegisterReturn<TFieldName> & { error?: string };
};

const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  props?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext, TTransformedValues> &
  ExtendedFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const result = _useForm<TFieldValues, TContext, TTransformedValues>(props);
  const extendedResult: ExtendedFormReturn<
    TFieldValues,
    TContext,
    TTransformedValues
  > = {
    onSubmit: result.handleSubmit,
    getInputProps: <
      TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
    >(
      name: TFieldName,
      options?: RegisterOptions<TFieldValues, TFieldName>
    ) => {
      return Object.assign(result.register<TFieldName>(name, options), {
        error: (lodash.get(result.formState.errors, name) as any)?.message
      });
    }
  };
  return Object.assign(result, extendedResult);
};

export { useForm };
