import { IAPIIndividualBankAccount } from '@zudi/types';

import { IRequestContext } from '../types/queries.ts';

const getBanks = async (requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualBankAccount[];
  }>(`/banks`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const getBank = async (id: string, requestContext: IRequestContext) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: IAPIIndividualBankAccount;
  }>(`/banks/${id}`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const postBank = async (
  data: IAPIIndividualBankAccount,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.post<{
    data: IAPIIndividualBankAccount;
  }>(`/banks`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const patchBank = async (
  id: string,
  data: IAPIIndividualBankAccount,
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.patch<{
    data: IAPIIndividualBankAccount;
  }>(`/banks/${id}`, data, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

const deleteBank = async (id: string, requestContext: IRequestContext) => {
  await requestContext.apiProvider.apiAuthenticated.delete<never>(
    `/banks/${id}`,
    {
      signal: requestContext.qfnContext?.signal
    }
  );
};

export { getBanks, getBank, postBank, patchBank, deleteBank };
