import {
  Text,
  Image,
  Button,
  Card,
  Group,
  LoadingOverlay
} from '@mantine/core';
import { Link } from 'react-router-dom';

import styles from './component.module.scss';

const DashboardLinkWrapper = ({
  url,
  isExternal,
  children
}: {
  url?: string;
  isExternal?: boolean;
  children?: React.ReactNode;
}) => {
  if (!url) {
    return <div className={styles.link}>{children}</div>;
  }

  return isExternal ? (
    <a className={styles.link} href={url} target="_blank">
      {children}
    </a>
  ) : (
    <Link className={styles.link} to={url ?? ''}>
      {children}
    </Link>
  );
};

const DashboardLink = ({
  title,
  url,
  isExternal,
  isLoading
}: {
  title: string;
  url?: string;
  isExternal?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <DashboardLinkWrapper url={url} isExternal={isExternal}>
      <LoadingOverlay visible={isLoading ?? false} />
      <Card
        w={300}
        h={300}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Card.Section>
          <Image
            src={
              'https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80'
            }
            height={160}
          />
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={500}>{title}</Text>
        </Group>

        <Button
          variant="light"
          color="bleudefrance"
          fullWidth
          mt="md"
          radius="md"
        >
          Acceder
        </Button>
      </Card>
    </DashboardLinkWrapper>
  );
};

export { DashboardLink };
