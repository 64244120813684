import { createProviderStateHook } from '@juandavidkincaid/utils';

import {
  DataAuthorizationProvider,
  DataAuthorizationProviderContext
} from './provider.tsx';

const useDataAuthorizationProvider = createProviderStateHook(
  DataAuthorizationProvider,
  DataAuthorizationProviderContext
);

export { useDataAuthorizationProvider };
