import { clsx } from 'clsx';

import styles from './component.module.scss';

const Card = ({
  className,
  children
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return <div className={clsx(styles.main, className)}>{children}</div>;
};

export { Card };
