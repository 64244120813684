import { Flex, Paper, SimpleGrid, Space, Title } from '@mantine/core';

const FormCard = ({
  title,
  actions,
  children
}: {
  title?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <Paper shadow="xs" p="xs" miw={800}>
      <Title order={4} weight={400}>
        {title}
      </Title>
      <Space h={5} />
      <SimpleGrid cols={4} spacing="xs" sx={{ alignItems: 'end' }}>
        {children}
      </SimpleGrid>
      <Space h={5} />
      <Flex w="100%" gap={'xs'}>
        {actions}
      </Flex>
    </Paper>
  );
};

export { FormCard };
