// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c7jTgox9QVMvZ3F6RveC{display:flex;height:35px;justify-content:center;align-items:center;border-radius:8px;padding:10px;outline:none;border:none;cursor:pointer;font-family:\"Montserrat\",sans-serif;font-size:14px;border:1px solid #3264a6;background-color:#3264a6;color:#fff}.c7jTgox9QVMvZ3F6RveC:hover{background-color:#fff;color:#3264a6}.c7jTgox9QVMvZ3F6RveC.iAHWeJ448fIQOndKdFQd{width:100%}", "",{"version":3,"sources":["webpack://./src/common/components/Button/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CAEA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CAEA,mCAAA,CACA,cAAA,CAEA,wBAAA,CACA,wBAAA,CACA,UAAA,CAEA,4BACE,qBAAA,CACA,aAAA,CAGF,2CACE,UAAA","sourcesContent":[".main {\n  display: flex;\n  height: 35px;\n\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 8px;\n  padding: 10px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n\n  font-family: 'Montserrat', sans-serif;\n  font-size: 14px;\n\n  border: 1px solid #3264a6;\n  background-color: #3264a6;\n  color: #fff;\n\n  &:hover {\n    background-color: #fff;\n    color: #3264a6;\n  }\n\n  &.fullWidth{\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "c7jTgox9QVMvZ3F6RveC",
	"fullWidth": "iAHWeJ448fIQOndKdFQd"
};
export default ___CSS_LOADER_EXPORT___;
