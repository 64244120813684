import { Link, useNavigate } from 'react-router-dom';
import { Burger, Divider, Menu } from '@mantine/core';
import { useState } from 'react';
import { IconLogout2, IconHome } from '@tabler/icons-react';

import {
  useAuthProvider,
  useHeaderProvider
} from '@/common/providers/index.ts';

import { Typography } from '../Typography/index.ts';
import FemCNGLogoLongImg from '../../../assets/logos/femcng-long.svg';

import styles from './component.module.scss';

const Header = () => {
  const { title } = useHeaderProvider();
  const navigate = useNavigate();
  const { cognitoUser, logoutUser } = useAuthProvider();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div>
          <Link to="/">
            <img src={FemCNGLogoLongImg} alt="FemCNG Logo" />
          </Link>
        </div>
        <div>
          <div className={styles.titles}>
            <Typography.Title variants={['large']}>{title}</Typography.Title>
            <Typography.Title>Portal Transaccional femCNG</Typography.Title>
          </div>
          {cognitoUser && (
            <>
              <Divider orientation="vertical" />
              <Menu
                shadow="md"
                width={200}
                opened={menuOpen}
                onChange={setMenuOpen}
              >
                <Menu.Target>
                  <Burger opened={menuOpen} />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>Usuario</Menu.Label>
                  <Menu.Item
                    icon={<IconHome size={14} />}
                    onClick={() => navigate('/')}
                  >
                    Dashboard
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconLogout2 size={14} />}
                    onClick={logoutUser}
                  >
                    Cerrar Sesión
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
