import { IRequestContext } from '../types/queries.ts';

const getIntegrationsUserStatementUrl = async (
  requestContext: IRequestContext
) => {
  const response = await requestContext.apiProvider.apiAuthenticated.get<{
    data: string;
  }>(`/integrations/user_statements`, {
    signal: requestContext.qfnContext?.signal
  });
  return response.data.data;
};

export { getIntegrationsUserStatementUrl };
